import { FETCH_DOCTORS } from "../actions/doctorActions";

const INIT_STATE = {
  doctors: [],
  isFetching: true
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_DOCTORS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
