 
  /**
   * Removes all items with keys the passed ones from the local storage.
   * @param keys The keys in the local storage
   */
  export const removeFromStorage = (...keys) => {
    if (keys == null) return;
    keys.forEach(k => localStorage.removeItem(k));
  };
  
  /**
   * Stores the properties of the object in local storage.
   * @param toStore An object of key - value pairs to store in local storage.
   */
  export const putInStorage = (toStore) => {
    Object.getOwnPropertyNames(toStore).forEach(k =>
      localStorage.setItem(k, "" + toStore[k])
    );
  };
  
  export const getAccessToken = () => localStorage.getItem("token");
  
  export const getExpiresAt = () => localStorage.getItem("expiresAt");
  
  export const getRefreshToken = () => localStorage.getItem("refreshToken");
  
  export const getToken = () => ({
    token: getAccessToken(),
    expiresAt: getExpiresAt(),
    refreshToken: getRefreshToken()
  });
  