import backend from "../util/backend";

export const FETCH_DOCTORS = "@@doctor/FETCH_DOCTORS";

export const fetchDoctors = () => dispatch => {
  dispatch({
    type: FETCH_DOCTORS,
    payload: { isFetching: true, doctors: [] }
  });
  backend()
    .get("doctor/all")
    .then(doctors =>
      dispatch({
        type: FETCH_DOCTORS,
        payload: { isFetching: false, doctors }
      })
    )
    .catch(e =>
      dispatch({
        type: FETCH_DOCTORS,
        payload: { isFetching: false, doctors: [] }
      })
    );
};
