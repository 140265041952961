import React, { Component } from "react";
import { connect } from "react-redux";

class RoleWrapper extends Component {
  render() {
    const { role, allowedRole } = this.props;
    if (role === allowedRole) return this.props.children;
    return null;
  }
}

export default connect(s => ({
  role: s.user.role
}))(RoleWrapper);
