import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/userActions";
import "./LoginPage.css";
import bigLogo from "../images/big_logo.png";

class LoginPage extends Component {
  state = {
    email: null,
    password: null
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.login(email, password);
  };

  render() {
    const { email, password } = this.state;
    return (
      <div>
        <div className="logo-holder">
          <img src={bigLogo} className="app-logo" alt="logo" />
        </div>
        <div className="login-strip">
          <form onSubmit={this.onSubmit} className="login-wrapper">
            <input
              className="login-input"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              type="email"
              placeholder="Имейл"
            />
            <input
              className="login-input"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              placeholder="Парола"
            />
            <br />
            <div>{this.props.errMsg}</div>
            <br />
            <div>
              <Link to="/forgot_password">Забравена парола ?</Link>
              <button type="submit" className="login-submit">
                Вход
              </button>
            </div>
          </form>
        </div>
        <div className="login-actualization-strip">
          <table width="135" border="0" cellPadding="2" cellSpacing="0" title="Click to Verify - This site chose GeoTrust SSL for secure e-commerce and confidential communications.">
          <tbody>
            <tr>
              <td width="135" align="center" valign="top">
                <script type="text/javascript" src="https://seal.geotrust.com/getgeotrustsslseal?host_name=vhospital-fbm.org&amp;size=M&amp;lang=en"></script>
                <br />
              <a href="http://www.geotrust.com/ssl/" target="_blank" 
                style={{color:"#000000", "textDecoration":"none", font:"bold 7px verdana,sans-serif", "letterSpacing":".5px", "textAlign":"center", margin:"0px", padding:"0px"}}></a></td>
            </tr>
          </tbody>
          </table>
          
          <div dangerouslySetInnerHTML={{ __html: '<!-- GeoTrust QuickSSL [tm] Smart  Icon tag. Do not edit. --> \n' 
            + '<script language="javascript" type="text/javascript" src="//smarticon.geotrust.com/si.js"></script>\n'
            + '<!-- end  GeoTrust Smart Icon tag -->'
          }} />

          {this.props.actualization ? (
            <div style={{ float: "right" }}>
              Последна актуализация: {this.props.actualization}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  s => ({
    actualization: s.user.appInfo.actualization,
    errMsg: s.user.errMsg
  }),
  { login }
)(LoginPage);
