import { push } from "connected-react-router";
import FileSaver from "file-saver";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { fetchDoctors } from "../actions/doctorActions";
import bigLogo from "../images/big_logo.png";
import backend from "../util/backend";
import { removeFromStorage } from "../util/storage";
import "./LoginPage.css";
import RoleWrapper from "./RoleWrapper";

class DoctorsTable extends Component {
  componentDidMount() {
    this.props.fetchDoctors();
  }

  getColumns = () => {
    const columns = [
      {
        Header: "Име",
        accessor: "name"
      },
      {
        Header: "Ложа",
        accessor: "lodge",
        filterMethod: (filter, row) => {
          if (filter.value === "Всички") return true;
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "Всички"}
          >
            {this.props.lodges.map(l => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: "Ориент",
        accessor: "orient",
        filterMethod: (filter, row) => {
          if (filter.value === "Всички") return true;
          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "Всички"}
          >
            {this.props.orients.map(l => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: "Специалност",
        accessor: "specialities",
        Cell: ({ value }) => value ? value.join("; ") : "",
        filterMethod: (filter, row) => {
          if (filter.value === "Всички") return true;
          return row[filter.id].includes(filter.value);
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "Всички"}
          >
            {this.props.specialities.map(l => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: "Месторабота",
        accessor: "workplaces",
        Cell: ({ value }) => value ? value.join("; ") : "",
        filterMethod: (filter, row) => {
          if (filter.value === "Всички") return true;
          return row[filter.id].includes(filter.value);
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "Всички"}
          >
            {this.props.workplaces.map(l => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: "Имeйл",
        accessor: "email",
        Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>
      },
      {
        Header: "Телефони",
        accessor: "phones",
        Cell: ({ value }) =>
          value ? value.map(v => (
            <a style={{ margin: "3px" }} key={v} href={`tel:${v}`}>
              {v}
            </a>
          )) : ""
      }
    ];

    return this.props.role !== "ADMIN"
      ? columns
      : [
          ...columns,
          {
            Header: "Действия",
            accessor: "actions",
            filterable: false,
            Cell: ({ original: { id } }) => (
              <button
                className="login-submit"
                style={{ backgroundColor: "red" }}
                onClick={() => this.deleteDoctor(id)}
              >
                Изтриване
              </button>
            )
          }
        ];
  };

  logout = () => {
    removeFromStorage("token", "refreshToken", "expiresAt");
    this.props.push("/login");
  };

  exportTable = () => {
    backend()
      .get("admin/csv/export")
      .then(data => {
        // add "\uFEFF" for BOM, god damn excel
        const blob = new Blob(["\uFEFF" + data], {
          type: "text/csv;charset=utf-8"
        });
        FileSaver.saveAs(blob, "doctors_table.csv");
      });
  };

  importTable = _e => {
    const file = document.getElementById("files").files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = reader.result;
      backend()
        .post("admin/csv/import", new Blob([fileContent]), {
          params: {
            file_name: file.name
          },
          headers: {
            "content-type": "application/octet-stream"
          }
        })
        .then(_ => window.location.reload());
    };
    reader.onabort = () => {};
    reader.onerror = () => {};

    reader.readAsArrayBuffer(file);
  };

  deleteDoctor = id => {
    backend()
      .delete(`doctor/${id}`)
      .then(_ => this.props.fetchDoctors())
      .catch(_ => this.props.fetchDoctors());
  };

  render() {
    return (
      <div>
        <Link to="/">
          <div className="logo-holder">
            <img src={bigLogo} className="app-logo" alt="logo" />
          </div>
        </Link>
        <br />
        <div
          className="logo-holder"
          style={{
            height: "40px",
            cursor: "auto",
            float: "none",
            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap-reverse",
            overflowWrap: "break-word"
          }}
        >
          <button
            className="login-submit"
            onClick={this.logout}
            style={{ marginLeft: "5px" }}
          >
            Изход
          </button>
          <button
            className="login-submit"
            onClick={() => this.props.push("/profile")}
            style={{ marginLeft: "5px" }}
          >
            Профил
          </button>
          <RoleWrapper allowedRole={"ADMIN"}>
            <button
              className="login-submit"
              style={{ marginLeft: "5px" }}
              onClick={() => this.props.push("/users")}
            >
              Потребители
            </button>
          </RoleWrapper>
          <RoleWrapper allowedRole={"ADMIN"}>
            <button
              className="login-submit"
              style={{ marginLeft: "5px" }}
              onClick={this.exportTable}
            >
              Експорт
            </button>
          </RoleWrapper>
          <RoleWrapper allowedRole={"ADMIN"}>
            <form onSubmit={() => {}} action="/" method="post">
              <label htmlFor="files" className="login-submit">
                <div style={{ paddingTop: 4 }}>Импорт</div>
              </label>
              <input
                id="files"
                onChange={this.importTable}
                style={{ visibility: "hidden", lineHeight: 0 }}
                type="file"
              />
            </form>
          </RoleWrapper>
        </div>
        <br />
        <ReactTable
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={this.getColumns()}
          data={this.props.doctors}
          previousText={"Назад"}
          nextText={"Напред"}
          pageText={"Стр."}
          loadingText={"Зарежда се..."}
          noDataText={"Няма намерени данни"}
          ofText={"от"}
          rowsText={"реда"}
        />
        <div>
          {this.props.secretary ? (
            <div style={{ float: "right" }}>
              Секретар: {this.props.secretary}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  s => ({
    doctors: s.doctor.doctors,
    lodges: [
      "Всички",
      ...Array.from(new Set((s.doctor.doctors || []).map(m => m.lodge))).sort()
    ],
    orients: [
      "Всички",
      ...Array.from(new Set((s.doctor.doctors || []).map(m => m.orient))).sort()
    ],
    specialities: [
      "Всички",
      ...Array.from(new Set(
        [].concat
          .apply(
            [],
            [...(s.doctor.doctors || []).map(m => m.specialities)]
          )
          .filter(v => v)
      )).sort()
    ],
    workplaces: [
      "Всички",
      ...Array.from(new Set(
        [].concat
          .apply(
            [],
            [...(s.doctor.doctors || []).map(m => m.workplaces)]
          )
          .filter(v => v)
      )).sort()
    ],
    isFetching: s.doctor.isFetching,
    secretary: s.user.appInfo.secretary,
    role: s.user.role
  }),
  { fetchDoctors, push }
)(DoctorsTable);
